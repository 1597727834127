@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

#projects {
  :global {
    min-height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 120px;
    .body {
      .title {
        @include title-page;
        @include animationHover("Recent Projects", $gray);
        animation-duration: 4s;
        margin-bottom: 6px;
      }

      .sub-title {
        @include p;
        @include transition;
        max-width: 70%;
        padding: 10px 50px 50px 0;
        border-radius: 10px;
        @include bgOnMd;

        @include mq($xlg) {
          max-width: 80%;
        }

        @include mq($xl) {
          max-width: 90%;
        }

        @include mq($lg) {
          max-width: 90%;
        }

        @include mq($md) {
          max-width: 100%;
        }
      }
    }

    .nsanzimfura-Projects {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100% - 30px);
      position: relative;
      flex-wrap: wrap;
      gap: 20px;

      @include mq($md) {
        max-width: 90% !important;
      }

      @include mq($sm) {
        max-width: 100% !important;
      }
    }

    .singleProjectSM {
      width: 320px;
      height: 320px;
      border-radius: 10px;
      @include background-img;
      @include pointer;
    }
  }
}
