@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.scrollableSection {
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin: 0;
  background-color: transparent;
  overflow: hidden !important;
  border: none;
  width: 100%;
  height: fit-content;
}
