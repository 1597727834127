@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.footer {
  :global {
    height: 100vh;
    padding: 0;
    margin: 0;
    width: 100%;
    border: none;
    margin-top: 50px;
    padding-top: 100px;
    position: relative;
    overflow: hidden !important;
    background-color: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 20px;
      flex-grow: 1;
      position: relative;
      z-index: 1;

      @include mq($md) {
        max-width: 95% !important;
      }

      @include mq($xls) {
        max-width: 100% !important;
      }

      .logo {
        width: 200px;
        aspect-ratio: 1/1;
        height: auto;
        @include pointer;

        @include mq($lg) {
          width: 180px;
        }

        @include mq($md) {
          width: 150px;
        }

        @include mq($xls) {
          width: 100px;
        }

        @include mq($sm) {
          width: 80px;
        }
      }
    }

    .copyRight {
      position: absolute;
      bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      flex-wrap: wrap;
      font-family: $sf-pro-medium;
      color: $light;
      padding-bottom: 20px;

      * {
        font-size: pxToEm(16);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        line-height: 1.3;
        padding: 0;
        margin: 0;

        @include mq($md) {
          font-size: pxToEm(14);
        }

        @include mq($sm) {
          font-size: pxToEm(12);
        }
      }

      h1 {
        font-family: $sf-pro-bold;
        font-size: inherit;
        @include transition;
        @include pointer;

        &:hover {
          color: $aqua;
        }
      }
    }

    .medias_xlg,
    .medias_lg {
      flex-grow: 1;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: absolute;
      left: pxToVw(100);
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      z-index: 3;
      gap: 30px;
      padding: 20px;
      padding-bottom: 100px !important;
      overflow: hidden;

      @include mq(1600px) {
        display: none;
      }

      .tree {
        background-color: none;
        padding: 0;
        margin-top: auto;

        .treeLink {
          color: $light;
          font-family: $sf-pro-medium;
          text-decoration: none;
          font-size: 2rem;
          @include pointer;
          @include transition;

          @include mq($md) {
            font-size: 1.5rem;
          }

          @include mq($sm) {
            font-size: 1.3rem;
          }

          &:hover {
            @include animationHover("LinkTree", $aqua);
          }
        }
      }
    }

    .medias_lg {
      display: none;

      @include mq(1600px) {
        display: flex;
        left: 0;
      }
    }
  }
}
