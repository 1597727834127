@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

#about {
  :global {
    min-height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 5;
    margin-top: -100px;
    .click-fit-bg-gif {
      inset: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      object-fit: cover;
      background-color: transparent;
    }

    #bgDots {
      display: flex;
    }

    .body {
      .about-text {
        width: 100%;

        .title {
          margin-bottom: 21px;
          @include title-page;
          @include animationHover("I am a Full stack developer", $gray);
          animation-duration: 4s;

          @include mq($xls) {
            margin-bottom: 17px;
          }
        }

        .sub-title {
          @include p;
          @include transition;
          max-width: 70%;
          padding: 10px 50px 50px 0;
          border-radius: 10px;
          @include bgOnMd;

          @include mq($xlg) {
            max-width: 80%;
          }

          @include mq($xl) {
            max-width: 90%;
          }

          @include mq($lg) {
            max-width: 90%;
          }

          @include mq($md) {
            max-width: 100%;
          }
        }

        .goals {
          display: grid;
          gap: pxToVw(40);
          grid-template-columns: 1.5fr 2fr;

          @include mq($xls) {
            display: flex;
            flex-direction: column;
          }

          .goalbox {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @include mq($md) {
              @include bgOnMd;
              padding: 15px;
              border-radius: 10px;
            }
            .years {
              @include h2;
              color: $white;
              font-weight: 900;
            }

            .goalbox-title {
              @include h2;
              font-family: $sf-pro-semi-bold;
            }

            .description-about {
              @include p;
            }

            .about-Nsanzimfura {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              transform-origin: left;
            }
          }
        }
      }
    }
  }
}
