@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.nsanzimfuraPopup {
  @include scroll-bar;
  :global {
    display: flex;
    @include bgOnMd;
    justify-content: center;
    align-items: center;
    @include pointer;
    .nsanzimfura-worksTitle {
      @include h3;
      font-family: $sf-pro-medium;
      font-weight: 900;
      color: $black;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
    .previewWrapper {
      padding: 0;
      margin: 0;
      .preview {
        width: 100%;
        padding: 0;
        margin: 0;
        aspect-ratio: 1920/1080;
        height: auto;
        @include background-img;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 10px;
        .hovered {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          inset: 0;
          background-color: transparent;
          text-decoration: none;
          overflow: hidden;
          @include transition;
          border-radius: inherit;
          img {
            width: 20px;
            height: 20px;
            @include pointer;
            @include transition;
            transform: scale(0);
            transition-delay: 0.7s;
          }
          &:hover {
            background-color: $btnColor;
            img {
              transform: scale(1.2);
            }
          }
        }
      }

      .detailsWrapper {
        width: 100%;
        height: fit-content;
        .detailsList {
          width: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          gap: 5px;
          justify-content: flex-start;
          align-items: flex-start;
          p {
            @include p;
            color: $btnColor;
          }
          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
              @include p;
              color: $listColor;
              position: relative;
              padding-left: 1.5em;
              font-family: $sf-pro-medium;
              display: inline-block;
              &::before {
                content: "🌸";
                position: absolute;
                left: 0;
                top: 0;
              }
              a {
                margin: 0 5px;
                font-family: $sf-pro-medium;
                text-decoration: none;
                color: $black;
                font-weight: 900;
                @include pointer;
                @include transition;
                display: inline-block;
                &:hover {
                  color: $aqua;
                }
              }
            }
          }
        }
      }
    }
    .nsanzimfura-modal-footer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h6 {
        color: $black;
        width: 100%;
        display: flex;
        align-items: flex-start;
        font-family: $sf-pro-bold;
        font-weight: 900;
      }
      div {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .badge {
          border-radius: 3px;
          padding: 5px 10px !important;
          background-color: $black !important;
          backdrop-filter: blur(34px) !important;
          @include pointer;
        }
      }
    }
  }
}
