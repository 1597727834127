@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.nsanzimfura_Btn {
  border: 0px solid $aqua;
  position: relative;
  box-shadow: inset -2px 1px 11px $btnColor;
  border-radius: 5px;
  background-color: $blackNew;
  padding: 0;
  @include pointer;
  z-index: 100;

  svg {
    position: absolute;
    inset: 0;
    fill: none;
    stroke: $aqua;
    stroke-width: 5px;
    stroke-dasharray: 33 297;
    stroke-dashoffset: 50;
    transition: all 0.5s linear;
    @include pointer;
  }

  #Layer_1 {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    padding: 0;
  }

  * {
    font-family: $sf-pro-medium;
    color: $dark;
  }

  &:hover {
    box-shadow: none;

    svg {
      stroke-width: 3px;
      stroke-dashoffset: -500;
    }

    * {
      font-family: $sf-pro-medium;
      color: $aqua;
    }
  }

  &:active {
    transform: scale(1.001);
    background-color: $pColor;

    * {
      color: $black !important;
    }
  }

  * {
    font-family: $sf-pro-medium;
    font-size: pxToEm(16);

    @include mq($md) {
      * {
        font-size: pxToEm(14);
      }
    }
  }

  .Children {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    width: 100%;
    height: 100%;
    gap: 10px;
    @include pointer;

    @include mq($xlg) {
      padding: pxToEm(18) pxToEm(24);
    }

    @include mq($lg) {
      padding: pxToEm(16) pxToEm(20);
    }

    @include mq($md) {
      padding: pxToEm(15) pxToEm(18);
    }
  }
}