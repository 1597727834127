@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.bottomSrcoll {
  :global {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    background-color: transparent;
    z-index: 3;
    img {
      width: pxToVw(60);
      width: pxToVw(60);
      @include pointer;
    }
    .scroll-home {
      transform-origin: top;
    }
    svg {
      path,
      line {
        stroke: $white;
        fill: $white;
      }
    }
  }
}
