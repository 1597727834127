@import "./variables.scss";

@mixin transition {
  transition: all 0.5s ease-in-out;
}

// pointer
@mixin pointer {
  cursor: url("../../public/images/cursorPointer.svg"), auto;
}

@mixin background-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin mq($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin mqh($size) {
  @media screen and (max-height: $size) {
    @content;
  }
}

@mixin scroll-bar {
  &::-webkit-scrollbar {
    width: 7px;

    @include mq($md) {
      width: 4px;
    }

    @include mq($xls) {
      display: none;
    }
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $black;

    @include mq($md) {
      width: 4px;
    }

    @include mq($xls) {
      display: none;
    }
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 20px;

    @include mq($xls) {
      display: none;
    }
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $light;

    @include mq($xls) {
      display: none;
    }
  }
}

@function pxToVh($element) {
  @return #{calc(($element / 1080) * 100)}vh;
}

@function pxToVw($element) {
  @return #{calc(($element / 1080) * 100)}vw;
}

@function pxToEm($element) {
  @return #{calc($element/16)}em;
}

//mixin margins and paddings
@mixin page-setting {
  background: transparent;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  font-family: $sf-pro-semi-bold;
  padding: pxToVh(50) pxToVw(100) pxToVh(50) pxToVw(100);

  @include mq($xls) {
    padding-top: pxToVh(50);
    padding-bottom: pxToVh(50);
  }
}

//title pages
@mixin title-page {
  font-weight: 500;
  font-size: pxToEm(50);
  text-align: left;
  color: $white;
  font-family: $sf-pro-semi-bold;

  @include mq($xlg) {
    font-size: pxToEm(40);
  }

  @include mq($xls) {
    font-size: pxToEm(30);
  }
}

@mixin animationHover($content, $color) {
  position: relative;
  color: $light;
  padding: 10px 0;
  @include transition;

  &:before {
    background: transparent;
    color: $color;
    overflow: hidden;
    position: absolute;
    content: $content;
    top: 0;
    left: 0;
    padding: 10px 0;
    -webkit-clip-path: polygon(0% 0%, 30% 0%, 30% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 30% 0%, 30% 100%, 0% 100%);
    animation: slide 10s linear infinite;
  }
}

@keyframes slide {
  50% {
    -webkit-clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
  }
}

@mixin bgOnMd {
  @include mq($lg) {
    background-color: $glass;
    backdrop-filter: blur(14px);
  }

  @include mq($xls) {
    padding-left: 15px;
  }
}

@mixin h1 {
  color: $white;
  font-family: $sf-pro-bold;
  font-weight: 900;
  font-size: pxToEm(80);

  @include mq($xlg) {
    font-size: pxToEm(70);
  }

  @include mq($lg) {
    font-size: pxToEm(60);
  }

  @include mq($md) {
    font-size: pxToEm(50);
  }

  @include mq($xls) {
    font-size: pxToEm(30);
  }

  @include mq($sm) {
    font-size: pxToEm(25);
  }
}

@mixin h2 {
  color: $white;
  font-family: $sf-pro-bold;
  font-size: pxToEm(35);
  line-height: 1.3;

  @include mq($lg) {
    font-size: pxToEm(28);
  }

  @include mq($xls) {
    font-size: pxToEm(23);
  }

  @include mq($sm) {
    font-size: pxToEm(18);
  }
}

@mixin h3 {
  color: $white;
  font-family: $sf-pro-medium;
  font-size: pxToEm(20);

  @include mq($xls) {
    font-size: pxToEm(18);
  }

  @include mq($xls) {
    font-size: pxToEm(16);
  }
}

@mixin p {
  color: $pColor;
  font-family: $sf-pro-medium;
  font-size: pxToEm(16);
  line-height: 1.6;
  display: flex;
  justify-content: flex-start;

  @include mq($md) {
    font-size: pxToEm(14);
  }

  @include mq($sm) {
    font-size: pxToEm(12);
  }
}
