@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap");

#hero {
  :global {
    height: 100vh;
    max-height: 100vh;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include mq($md) {
      padding-top: 20px;
    }

    .hero-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 20vh;
      flex: 1 1 50%;
      background-color: transparent;

      @include mq($lg) {
        margin-top: auto;
        margin-bottom: auto;
        flex: 0 0 20%;
      }

      @include mq($md) {
        @include bgOnMd;
        padding: 20px;
        border-radius: 10px;
        width: calc(100% - 40px);
      }

      @include mq($sm) {
        width: calc(100% - 20px);
      }

      .title-home {
        display: flex;
        gap: 10px;
        align-items: flex-start;

        span {
          font-family: $sf-pro-medium;
          font-size: pxToEm(35);
          display: flex;
          align-items: center;
        }

        h1 {
          @include h1;
          font-family: "Inter", sans-serif;
          font-optical-sizing: auto;
          @include animationHover("Imfura", $light);
          color: $aqua;
          display: flex;
          align-items: flex-end;
          margin-top: -40px;

          @include mq($lg) {
            margin-top: -20px;
          }

          @include mq($xls) {
            margin-top: -10px;
          }
        }
      }

      .invisibleOnSm {
        @include mq($xms) {
          display: none;
        }
      }

      .sub-heading {
        @include p;
        font-size: pxToEm(25);

        @include mq($xl) {
          font-size: pxToEm(22);
        }

        @include mq($md) {
          font-size: pxToEm(20);
        }

        @include mq($xls) {
          font-size: pxToEm(18);
        }
      }

      .small {
        @include p;
        font-size: pxToEm(16);

        @include mq($xl) {
          font-size: pxToEm(14);
        }

        @include mq($md) {
          font-size: pxToEm(12);
        }

        @include mq($xls) {
          font-size: pxToEm(10);
        }
      }
    }

    .contact-nsanzimfura {
      margin-top: 10vh !important;

      @include mq($md) {
        position: relative !important;
        z-index: 100;
      }
    }
  }
}
