@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.carouselWrapper {
  :global {
    height: 100%;
    width: 100%;
    max-width: 100% !important;
    width: 100%;
    height: 600px;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    z-index: 2;

    @include mq($xlg) {
      height: 500px;

    }

    @include mq($xl) {
      height: 460px;
    }

    .carouselflow {
      position: absolute;
      top: 50%;
      left: 50%;
      backface-visibility: visible;
      transform-style: preserve-3d;
      transform-origin: 50% 50%;
      cursor: pointer;
      transition: 1s all ease-out;
    }

    .carouselflow-item {
      width: 400px;
      height: 400px;

      @include mq($xlg) {
        height: 350px;
        width: 350px;
      }

      @include mq($xl) {
        height: 320px;
        width: 320px;
      }

      transform-origin: 50% 100%;
      position: absolute;
      backface-visibility: visible;
      top: -160px;
      left: -160px;
      border: 5px solid $black;
      border-radius: 10px;
      background-color: $black;
      @include background-img;
      transition: 1.25s all cubic-bezier(0.17, 0.75, 0.58, 0.95);
      box-shadow: 0px 3px 3px 0px $gray;
      @include pointer;

      &:hover {
        border-color: $aqua;
      }
    }

    .image-display {
      position: fixed;
      width: 1000px;
      @include pointer;
      height: 600px;
      top: 50%;
      left: 50%;
      margin-top: -300px;
      margin-left: -500px;
      border: 2px solid $white;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: 0.5s all ease-out;
      transform: scale(0, 0);

      @include mq(999px) {
        width: 100%;
        margin-left: -50%;
      }
    }

    @keyframes logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      a:nth-of-type(2) .logo {
        animation: logo-spin infinite 20s linear;
      }
    }

    .card {
      padding: 2em;
    }

    .read-the-docs {
      color: $aqua;
    }
  }
}

.nsanzimfura_singleProject {
  :global {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    @include transition;
    @include pointer;

    .nsanzimfura-Bg {
      position: absolute;
      inset: 0;
      z-index: 0;
      @include background-img;

      @include mq($sm) {
        border-radius: 10px;
      }
    }

    .nsanzimfura_bottom {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      height: fit-content;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .name {
      width: 100%;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      h3 {
        @include p;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        font-family: $sf-pro-medium;
        font-weight: 900;
        border-radius: 5px;
        color: $light;
        opacity: 1;
        background-color: $black;
      }
    }

    .btns {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      transform-origin: left;
      position: absolute;
      bottom: -50%;
      opacity: 0;
      @include transition;

      img {
        height: 16px;
        width: 25px;
      }

      @include mq($lg) {
        position: relative;
        bottom: 10px;
        opacity: 1;
      }
    }

    .btns,
    .nsanzimfura_bottom,
    .nsanzimfura-Bg {
      &:hover {
        .btns {
          bottom: 10px;
          opacity: 1;
        }
      }
    }
  }
}