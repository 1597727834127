@import url("https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&family=Arima:wght@100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&family=Arima:wght@100..700&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Junge&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&family=Arima:wght@100..700&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Junge&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$white: #000000;
$light: #000000;
$black: #ffffff;
$background: #ffffff;
$blackNew: #171717;
$aqua: #8affff;
$gray: #b5a99c;
$glass: rgba(255, 255, 255, 0.1);
$IconShadow: rgba(255, 255, 255, 0.3);
$pColor: rgba(0, 0, 0, 0.8);
$btnColor: rgba(255, 255, 255, 0.5);
$listColor: rgba(255, 255, 255, 0.78);
$dark: #ffffff;

//  fonts
$sf-pro-regular: "Poppins", sans-serif;
$sf-pro-medium: "Poppins", sans-serif;
$sf-pro-semi-bold: "Arapey", serif;
$sf-pro-bold: "Inknut Antiqua", serif;

// screens
$sm: 480px;
$small-device: 500px;
$xxs: 320px;
$xls: 600px;
$xlsh: 500px;
$xms: 375px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xlg: 1440px;
$xxlg: 5000px;
$headervh: 100vh;
$header-padding: 10vw;
$divider: calc(4 / 3);
$xd-width: 1920;
$xd-height: 1080;
$width-of-container: calc(85px / $divider);
$menu-height: 54px;
