@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.IconsButton {
  width: 70px;
  aspect-ratio: 1/1;
  height: auto;
  @include pointer;
  @include transition;
  display: inline-block;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  margin: 0;
  background-color: transparent;
  text-align: center;
  line-height: 70px;
  box-shadow: 0 4px 4px $IconShadow;

  @include mq($xl) {
    width: 50px;
    line-height: 50px;
  }

  @include mq($lg) {
    width: 45px;
    line-height: 45px;
  }

  @include mq($md) {
    width: 50px;
    line-height: 50px;
  }

  @include mq($sm) {
    width: 40px;
    line-height: 40px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    aspect-ratio: 1/1;
    height: auto;
    background-color: $pColor;
    z-index: -1;

    @include mq($xl) {
      width: 45px;
    }

    @include mq($lg) {
      width: 40px;
    }

    @include mq($md) {
      width: 45px;
      line-height: 45px;
    }

    @include mq($sm) {
      width: 35px;
      line-height: 35px;
    }
  }

  img {
    width: 40px;
    aspect-ratio: 1/1;
    height: auto;
    @include pointer;
    @include transition;

    @include mq($xl) {
      width: 35px;
    }

    @include mq($lg) {
      width: 30px;
    }

    @include mq($md) {
      width: 35px;
    }

    @include mq($sm) {
      width: 20px;
    }
  }

  &:hover {
    background-color: $aqua;
    transform: rotate(135deg);
    box-shadow: 0 0 5px $aqua, 0 0 10px $aqua;

    img {
      transform: rotate(225deg);
    }
  }
}