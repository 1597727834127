@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

#preloader {
  :global {
    min-height: 100vh;
    min-width: 100vw;
    display: grid;
    place-items: center;
    position: fixed;
    z-index: 1000000000;
    top: 0;
    left: 0;
    background-color: $dark;
  }
}
