@import "../src/styles/variables.scss";
@import "../src/styles/mixins.scss";
@import "node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  background: $black;
  padding: 0;
  font-family: $sf-pro-regular;
  overflow-x: hidden;
  @include scroll-bar;
  cursor: url("../public/images/cursor.svg"), auto;

  ::selection {
    background-color: $aqua;
    color: $dark;
  }
  ::-webkit-selection {
    background-color: $aqua;
    color: $dark;
  }
  ::-moz-selection {
    background-color: $aqua;
    color: $dark;
  }
}
* {
  box-sizing: border-box;
  font-family: $sf-pro-regular;
  font-size: 16px;
  color: $white;
}
code {
  font-family: $sf-pro-regular;
}

#webpack-dev-server-client-overlay-div {
  display: none !important;
}
