#webgl {
  position: fixed;
  z-index: -1;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  // opacity: 0.5;
  top: 0;
}
