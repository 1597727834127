@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

#contact {
  :global {
    min-height: 100vh;
    justify-content: center;
    overflow: hidden;
    display: flex;
    position: relative;
    .container_wrapper {
      flex-direction: column;
      gap: 20px;
      margin-top: 5vh;
      position: relative;
      z-index: 3;
    }

    .title {
      margin-bottom: 21px;
      @include title-page;
      @include animationHover("Let's Talk", $gray);
      animation-duration: 4s;

      @include mq($xls) {
        margin-bottom: 17px;
      }
    }

    p {
      @include p;
      display: inline-block;
      @include bgOnMd;
      padding: 20px 20px 20px 0;
      border-radius: 10px;
      max-width: fit-content;
    }

    .hello {
      color: $aqua;
    }

    .booking {
      padding: 0;
      margin: 0;

      iframe {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        object-fit: contain;
        border-radius: 10px;
        @include scroll-bar;

        @include mq($md) {
          min-height: 400px;
        }

        * {
          @include scroll-bar;
        }
      }
    }

    .mediasWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      h3 {
        font-family: $sf-pro-semi-bold;
        color: $light;
        font-size: pxToEm(35);

        @include mq($lg) {
          font-size: pxToEm(30);
        }

        @include mq($md) {
          font-size: pxToEm(25);
          display: none !important;
        }
      }

      .medias {
        flex-grow: 1;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        transform: rotate(-45deg);
        padding-top: 0;

        @include mq($md) {
          transform: rotate(0);
          gap: 25px;
        }

        @include mq($sm) {
          gap: 20px;
          display: none !important;
        }
      }
    }
  }
}
