@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.navbarComponent {
  :global {
    background-color: transparent;
    gap: 30px;
    position: relative;
    z-index: 10000;

    .navbarOpened {
      position: fixed;
      @include bgOnMd;
      inset: 0;
      z-index: 1000;
      min-width: calc(100vw - 40px);
      height: 100vh;
      padding: 20px;
      max-width: 100% !important;

      @include mqh($xxs) {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .logo {
      @include pointer;
      background-color: transparent;
    }

    img {
      height: 80px;
      aspect-ratio: 1/1;
      width: auto;
      // mix-blend-mode: difference;
      @include pointer;
    }

    .menu-icon-home {
      transform-origin: left;
      @include pointer;
      height: 40px;
      aspect-ratio: 1/1;
      width: auto;
    }

    .navBarLinks {
      justify-content: center;
      align-items: center;
      background-color: transparent;
      gap: 20px;

      .single-link {
        color: $white;
        font-size: pxToEm(18);
        font-weight: 500;
        @include transition;
        @include pointer;
        font-family: $sf-pro-medium;

        @include mq($xl) {
          font-size: pxToEm(16);
        }

        @include mq($lg) {
          font-size: 1.8rem;
        }

        &:hover {
          color: $aqua;
        }

        &:nth-child(1) {
          &:hover {
            @include animationHover("Home", $aqua);
          }
        }

        &:nth-child(1) {
          &:hover {
            @include animationHover("Home", $aqua);
          }
        }

        &:nth-child(2) {
          &:hover {
            @include animationHover("About", $aqua);
          }
        }

        &:nth-child(3) {
          &:hover {
            @include animationHover("Projects", $aqua);
          }
        }

        &:nth-child(4) {
          &:hover {
            @include animationHover("Contact", $aqua);
          }
        }

        &:nth-child(5) {
          &:hover {
            @include animationHover("Resume", $aqua);
          }
        }
      }
    }
  }
}
