@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

#sideNav {
  :global {
    position: fixed;
    top: pxToVw(130);
    bottom: 0;
    right: pxToVw(100);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    max-width: 22px;
    margin: 0;
    padding: 0;
    @include mq($xls) {
      display: none;
    }
    .points {
      display: grid;
      gap: 30px;
      place-items: center;
      @include transition;
      .point-circle {
        width: 10px;
        aspect-ratio: 1/1;
        height: auto;
        background: $white;
        border-radius: 50%;
      }
      .point {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        @include pointer;
        aspect-ratio: 1/1;
        height: auto;
        border: 1px solid transparent;
      }
      .active {
        @include transition;
        border: 1px solid $white;
        border-radius: 50%;
        padding: 0;
      }
    }
  }
}
